.btn-primary {

    background-color: #109E38;
    border-color: #109E38;
}


.row.justify-content-center.align-items-center {
    min-width: 100% !important;
}

.testi-contain.text-white {
    min-height: 300px;
}
.navbar-header .logo-lg img{max-width: 90%;}

.bg-light-box{background-color: #fbfaff;}
.card{box-shadow: none;}

.country_flags{width: 25px;height: 25px;overflow: hidden;display: block;float: left;margin-right: 6px;border-radius: 14px;}
.country_flags img{display: flex;vertical-align: middle;width: 100%; max-width: 25px; max-height: 25px;}
.dataTables_scroll table thead td{color: #1d1d1d; font-weight: 500;}
.dataTables_scroll table tbody td{color: #2d2d2d;}

.price-table{width: 100%;}
.price-table tr td img{max-width: 90px;}
.price-table, .price-table tr, .price-table tr td{    border: 1px solid #ededed;padding: 10px;}
a.d-block.auth-logo img {width: 100%;}